import { render, staticRenderFns } from "./goods_home.vue?vue&type=template&id=247751a9&scoped=true&"
import script from "./goods_home.vue?vue&type=script&lang=js&"
export * from "./goods_home.vue?vue&type=script&lang=js&"
import style0 from "./goods_home.vue?vue&type=style&index=0&id=247751a9&prod&scoped=true&lang=css&"
import style1 from "./goods_home.vue?vue&type=style&index=1&id=247751a9&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "247751a9",
  null
  
)

export default component.exports